import { json, LinksFunction, LoaderFunction } from '@remix-run/node';
import { Form, Link, Outlet } from '@remix-run/react';
import { useState } from 'react';
import blog_icon from '@/assets/images/blog_icon.png';
import contact_icon from '@/assets/images/contact_icon.png';
import insta_icon from '@/assets/images/insta_icon.png';
import logo from '@/assets/images/logo.png';
import searchIcon from '@/assets/images/search_icon.png';
import youtube_icon from '@/assets/images/youtube_icon.png';
import styles from '@assets/css/client-common.css?url';
import close_icon from '@assets/images/close_icon.png';
import page_icon from '@assets/images/page_icon.png';
import { menuItems } from '@common/menu.info';
import MainLoadingSpinner from '@components/MainLoadingSpinner';
import useHeaderScroll from '@hooks/useHeaderScroll';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export const loader: LoaderFunction = async ({ request }) => {
    return json({});
};

type SubMenuProps = {
    to: string;
    children: React.ReactNode;
    resetStates: () => void;
    className: string;
};

type MenuUnitProps = {
    to: string;
    onClick: (event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => void;
    children: React.ReactNode;
    subOpen: string;
    subMenus: Array<{ name: string; to: string }>;
    resetStates: () => void;
};

export default function ClientLayout() {
    return (
        <>
            <Header />
            <Outlet />
            <FloatIcon />
            <Footer />
            <MainLoadingSpinner />
        </>
    );
}

const SubMenu = ({ to, children, resetStates, className }: SubMenuProps) => (
    <Link to={to} className={className} onClick={resetStates}>
        {children}
    </Link>
);

const MenuUnit = ({ to, onClick, children, subOpen, subMenus, resetStates }: MenuUnitProps) => (
    <div className="menu_unit">
        <Link to="#" className="menu dp1" onClick={onClick}>
            {children}
        </Link>
        <div className={`sub_menu_list ${subOpen}`}>
            <div className="sub_in">
                {subMenus.map((subMenu, index) => (
                    <SubMenu key={index} to={subMenu.to} resetStates={resetStates} className={'sub_menu'}>
                        {subMenu.name}
                    </SubMenu>
                ))}
            </div>
        </div>
    </div>
);

const MenuPopUnit = ({ to, onClick, children, subOpen, subMenus, resetStates }: MenuUnitProps) => (
    <div className={`menu_pop_unit ${subOpen}`}>
        <div className="menu_pop_tit" onClick={onClick} role="presentation">
            <p className="menu_tit">{children}</p>
            <img className="menu_tit_icon" src={page_icon} alt="" />
        </div>
        <div className="menu_pop_dtl_list">
            {subMenus.map((subMenu, index) => (
                <SubMenu key={index} to={subMenu.to} resetStates={resetStates} className={'menu_pop_dtl'}>
                    {subMenu.name}
                </SubMenu>
            ))}
        </div>
    </div>
);

function Header() {
    const [pcAct, setPcAct] = useState('');
    const [moAct, setMoAct] = useState('');
    const [open, setOpen] = useState('');
    const [subOpen, setSubOpen] = useState(['', '', '', '', '']);
    const { scroll } = useHeaderScroll();

    const menuClick = (index) => {
        if (moAct === 'moAct') {
            setSubOpen((prevState) => {
                const newState = [...prevState];
                newState[index] = newState[index] === '' ? 'open' : '';
                return newState;
            });
        }

        if (pcAct === 'pcAct') {
            setSubOpen((prevState) => {
                const newState = [...prevState];
                newState[index] = newState[index] === '' ? 'open' : '';
                return newState;
            });
        }
    };

    const resetStates = () => {
        setSubOpen(['', '', '', '', '']);
        setOpen('');
        setMoAct('');
        setPcAct('');
    };

    return (
        <header className={`${scroll} ${moAct}`}>
            <div className="in">
                <span className="logo_href">
                    <span className="logo">
                        <Link to="/" className="logo_in">
                            <img className="logo_img" src={logo} alt="" />
                        </Link>
                    </span>
                </span>

                <div className="header_mo_grp">
                    <div
                        role="presentation"
                        className="moIcon"
                        onClick={(e) => {
                            setMoAct(moAct === '' ? 'moAct' : '');
                            setOpen(open === '' ? 'open' : '');
                            setSubOpen(['', '', '', '', '']);
                        }}
                    >
                        <span className="moLine"></span>
                        <span className="moLine"></span>
                        <span className="moLine"></span>
                    </div>
                </div>

                <div className={`menu_con ${open}`}>
                    <div className="list">
                        <div className="search_unit">
                            <Form method="GET" action="/search">
                                <div className="search_con">
                                    <input
                                        className="search_input"
                                        name={'menuName'}
                                        type="text"
                                        placeholder="상품명을 입력해주세요."
                                    />
                                    <button className="search_btn">
                                        <img className="com_img" src={searchIcon} alt="" />
                                    </button>
                                </div>
                            </Form>
                        </div>
                        <div className="for_pc">
                            <div
                                className="mo_icon pc"
                                role="presentation"
                                onClick={(e) => {
                                    setPcAct(pcAct === '' ? 'pcAct' : '');
                                    setOpen(open === '' ? 'open' : '');
                                    setSubOpen(['', '', '', '', '']);
                                }}
                            >
                                <span className="mo_line"></span>
                                <span className="mo_line"></span>
                                <span className="mo_line"></span>
                            </div>
                            <div className={`menu_pop ${open === 'open' ? 'show' : ''}`}>
                                <div className="menu_pop_in">
                                    <button
                                        className="menu_pop_close"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            resetStates();
                                        }}
                                    >
                                        <img className="com_img" src={close_icon} alt="" />
                                    </button>
                                    <div className="menu_pop_list">
                                        <div className="menu_pop_logo">
                                            <img className="com_img" src={logo} alt="" />
                                        </div>
                                        {menuItems.map((item, index) => (
                                            <MenuPopUnit
                                                key={index}
                                                to="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    menuClick(index);
                                                }}
                                                subOpen={subOpen[index]}
                                                subMenus={item.subMenus}
                                                resetStates={resetStates}
                                            >
                                                {item.name}
                                            </MenuPopUnit>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {menuItems.map((item, index) => (
                            <MenuUnit
                                key={index}
                                to="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    menuClick(index);
                                }}
                                subOpen={subOpen[index]}
                                subMenus={item.subMenus}
                                resetStates={resetStates}
                            >
                                {item.name}
                            </MenuUnit>
                        ))}
                    </div>
                </div>
            </div>
        </header>
    );
}

function Footer() {
    return (
        <footer>
            <div className={'in'}>
                <div className={'f_top'}>
                    <div className={'f_logo'}>
                        <img className={''} src={logo} alt={''} />
                    </div>
                    <div className={'f_con'}>
                        <div className={'unit'}>
                            <div className={'item'}>
                                <p className={'tit'}>{'CSCENTER'}</p>
                                <p className={'copy'}>
                                    <Link className={'copy'} to={'tel:02-515-7999'}>
                                        {'02-515-7999'}
                                    </Link>{' '}
                                    {'| 운영시간 9:00 - 5:00 | 점심시간 14:00 - 16:00'}
                                </p>
                            </div>
                            <div className={'item'}>
                                <p className={'tit'}>{'BANK INFO'}</p>
                                <p className={'copy'}>{'우리은행 1005-702-226737 예금주 : 김주연(꼬륵본점)'}</p>
                            </div>
                        </div>
                        <div className={'unit'}>
                            <div className={'item'}>
                                <p className={'tit'}>{'COMPANY'}</p>
                                <p className={'copy'}>
                                    {'꼬륵 대표자: 김주연 이메일: coreuk@naver.com'}
                                    <br />
                                    {'사업자 등록번호: 107-19-55225'} {/*통신판매신고번호: 0000-0000-0000*/}
                                    <br />
                                    {'대표번호: 1522-4992 FAX: 050 4-090-3885'}
                                    <br />
                                    {'주소: 강남구 논현로 151길 11(구:신사동 563-21) 1층 꼬륵'}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'f_bottom'}>
                    <div className={'copy_right'}>
                        <p className={'copy'}>{'Copyright 2024 꼬륵 Corp. All Rights Reserved.'}</p>
                        <div className={'f_menu_list'}>
                            <Link to={'/intro'} className={'copy f_menu'}>
                                {'회사소개'}
                            </Link>
                            <Link to={'#'} className={'copy f_menu'}>
                                {'이용약관'}
                            </Link>
                            <Link to={'#'} className={'copy f_menu'}>
                                {'쇼핑몰이용안내'}
                            </Link>
                            <Link to={'#'} className={'copy f_menu'}>
                                {'개인정보처리방침'}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

function FloatIcon() {
    return (
        <div className={'float_con'}>
            <div className={'in'}>
                <Link
                    to={'https://www.instagram.com/coreuk_doshirak'}
                    target="_blank"
                    className={'float_unit ty_02'}
                    rel="noreferrer"
                >
                    <img className={'float_img'} src={insta_icon} alt={''} />
                </Link>
                <Link
                    to={'https://blog.naver.com/coreuk/223392780205'}
                    target="_blank"
                    className={'float_unit ty_02'}
                    rel="noreferrer"
                >
                    <img className={'float_img '} src={blog_icon} alt={''} />
                </Link>
                <Link
                    to={'https://www.youtube.com/@TV-bz9uf'}
                    target="_blank"
                    className={'float_unit ty_02'}
                    rel="noreferrer"
                >
                    <img className={'float_img '} src={youtube_icon} alt={''} />
                </Link>
                <Link to={'https://pf.kakao.com/_xdsxjDG'} target="_blank" className={'float_unit'} rel="noreferrer">
                    <img className={'float_img'} src={contact_icon} alt={''} />
                </Link>
            </div>
        </div>
    );
}
