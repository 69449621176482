import { useEffect, useState } from 'react';

function useHeaderScroll() {
    // 스크롤이 50px 이상 내려올경우 true값을 넣어줄 useState
    const [scroll, setScroll] = useState('');

    useEffect(() => {
        if (window !== undefined) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (window !== undefined) {
                window.removeEventListener('scroll', handleScroll); //clean up
            }
        };
    }, []);

    const handleScroll = () => {
        if (window !== undefined) {
            if (window.scrollY > 0) {
                setScroll('headerAct');
            } else {
                setScroll('');
            }
        }
    };

    return { scroll };
}

export default useHeaderScroll;
